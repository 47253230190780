import { Controller } from '@hotwired/stimulus'

// This controller handles the gift message functionality in the membership form
// It manages the visibility of the message input area and updates the form data
export default class extends Controller {
    // Notes:
    // When the page loads, Stimulus looks for elements with data-controller="gift-message"
    // The data-controller in membership_form_component.html.erb tells Stimulus to connect that element to this controller
    // The connect method is called automatically when the controller is connected

    // This tells Stimulus to look for elements with:
    // - data-gift-message-target="messageArea" (stored in this.messageAreaTarget)
    //   This is the container div that holds the message input field and label
    //   Used to show/hide the entire message input section
    //
    // - data-gift-message-target="toggleButton" (stored in this.toggleButtonTarget)
    //   This is the button that toggles the visibility of the message area
    //   The text content of this button updates based on the visibility state
    //
    // - data-gift-message-target="messageInput" (stored in this.messageInputTarget)
    //   This is the textarea element where users type their gift message
    //   Connected via data: { gift_message_target: 'messageInput' } in the ERB template
    static targets = ['messageArea', 'toggleButton', 'messageInput']

    // Called when the controller is connected to the DOM
    // This happens automatically when Stimulus finds an element with data-controller="gift-message"
    connect() {
        // Initially hide the message area by adding the 'hidden' class
        // The messageAreaTarget is found via data-gift-message-target="messageArea"
        this.messageAreaTarget.classList.add('hidden')

        // Hide the message area if the message input is empty
        // The allows the message area to:
        // 1) Be hidden on page load
        // 2) Visible if the user types in the message input, form is submitted, but there was an error
        if (this.messageInputTarget.value.trim().length > 0) {
            this.messageAreaTarget.classList.remove('hidden')
        } else {
            this.messageAreaTarget.classList.add('hidden')
        }

        // Set the initial button text based on the visibility state
        this.updateToggleButtonText()
    }

    // Toggles the visibility of the message input area
    // This is called when the button is clicked via data-action="gift-message#toggleMessage"
    // The data-action attribute in the ERB template connects the button's click event to this method
    toggleMessage() {
        // Toggle the 'hidden' class on the message area container
        this.messageAreaTarget.classList.toggle('hidden')

        // If we're showing the message area (i.e., hidden class was just removed)
        // Focus on the message input field
        if (!this.messageAreaTarget.classList.contains('hidden')) {
            // Use requestAnimationFrame to ensure the textarea is visible before focusing
            // This prevents focus issues that can occur when trying to focus a hidden element
            requestAnimationFrame(() => {
                this.messageInputTarget.focus()
            })
        }

        // Update the button text to reflect the new state
        this.updateToggleButtonText()
    }

    // Updates the button text based on whether the message area is visible
    // Called both on initial connect and whenever the visibility is toggled
    updateToggleButtonText() {
        // Check if the message area is visible by looking for the 'hidden' class
        const isVisible = !this.messageAreaTarget.classList.contains('hidden')
        // Update the button text based on visibility
        // The toggleButtonTarget is found via data-gift-message-target="toggleButton"
        this.toggleButtonTarget.textContent = isVisible ? 'Remove message' : 'Send a message with your gift »'
    }
}