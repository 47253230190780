import { Controller } from '@hotwired/stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = [
                    // general
                    'step', 'error', 'reportToken', 'amount',
                    // upload
                    'dropzoneContainer', 'dropzone', 'fileInput', 'browseButton',
                    'uploadConfirmation', 'filenameDisplay', 'uploadAgainButton',
                    // identification
                    'sessionToken', 'emailField', 'isSupporter',
                    'skipRegistration', 'acceptNewsletter', 'signinEmail',
                    'signinPassword', 'registerUsername', 'registerEmail',
                    'registerPassword', 'skipEmail',
                    // tos
                    'paymentCouponContainer', 'paymentMethod', 'monthlyButton',
                    'onetimeButton', 'tosCheckbox', 'couponContainer', 'couponField',
                    'couponButton', 'couponApplied', 'couponValue', 'discount',
                    'discountError', 'tosSubmit',
                    // processing
                    'progressBarCompleted', 'progressBarIncomplete', 'progressPercentage',
                    'progressStepText', 'processingMessage', 'closeContainer',
                    'progressBarContainer'
                  ];

  static values = { avgTimeToRun: Number }

  connect() {
    this.currentStep = parseInt(this.element.dataset.initialStep, 10) || 0;
    this.file = null;
    this.showCurrentStep();
  }

  disconnect() {
    this.subscription?.unsubscribe();
  }

  // STEP HANDLING
  showCurrentStep() {
    this.stepTargets.forEach((step, index) => {
      step.classList.toggle('hidden', index !== this.currentStep);
    });
  }

  nextStep() {
    this.currentStep++;
    this.clearError();
    this.showCurrentStep();
  }

  // HELPERS
  hideElement(element) { element.classList.add('hidden'); }
  showElement(element) { element.classList.remove('hidden'); }

  // UPLOAD STEP
  onDragOver(event) { event.preventDefault(); this.highlightDropzone(true); }
  onDragLeave() { this.highlightDropzone(false); }
  onDrop(event) {
    event.preventDefault();
    this.highlightDropzone(false);
    if (event.dataTransfer.files.length > 0) {
      this.fileInputTarget.files = event.dataTransfer.files;
      this.handleFileUpload(event.dataTransfer.files[0]);
    }
  }

  onFileChange() {
    if (this.fileInputTarget.files.length > 0) {
      this.handleFileUpload(this.fileInputTarget.files[0]);
    }
  }

  onBrowseClick() { this.fileInputTarget.click(); }
  onUploadAgainClick() { this.toggleUploadVisibility(true); }

  highlightDropzone(highlight) { this.dropzoneTarget.style.borderColor = highlight ? '#007bff' : '#ccc'; }

  handleFileUpload(file) {
    this.clearError();
    if (file.size > 52428800) return this.setError('The file size should be less than 50MB.');
    if (file.size < 2097152) return this.setError('The file size should be more than 2MB.');
    if (file.name.includes('ancestry_composition')) {
      this.showElement(this.errorTarget);
      return this.errorTarget.innerHTML = 'This is the wrong 23andMe file. <a href="https://you.23andme.com/tools/data/" target="_blank">Download the correct zip archive</a>.';
    }
    this.file = file;
    this.filenameDisplayTarget.textContent = file.name;
    this.toggleUploadVisibility(false);
  }

  toggleUploadVisibility(showDropzone) {
    this.dropzoneContainerTarget.style.display = showDropzone ? 'block' : 'none';
    this.uploadConfirmationTarget.style.display = showDropzone ? 'none' : 'block';
  }

  // IDENTIFICATION STEP
  signIn(event) {
    event.preventDefault();
    this.clearError();
    axios.post('/api/v1/sessions', { session: { email: this.signinEmailTarget.value, password: this.signinPasswordTarget.value, new_wizard: true } })
      .then(({ data }) => data.error ? this.setError(data.error) : this.handleSignin(data))
      .catch(() => this.setError());
  }

  register(event) {
    event.preventDefault();
    this.clearError();
    axios.post('/api/v1/users', { authenticity_token: document.querySelector('meta[name="csrf-token"]').content, user: this.getUserData() })
      .then(({ data }) => { data.error ? this.setError(data.error) : this.handleRegister(data) })
      .catch(() => this.setError());
  }

  skipRegistration(event) {
    event.preventDefault();
    this.clearError();
    axios.get(`/api/v1/users/${btoa(this.skipEmailTarget.value)}`)
      .then(({ data }) => data.error ? this.setError(data.error) : this.handleSkip(this.skipEmailTarget.value))
      .catch(() => this.setError());
  }

  getUserData() {
    return {
      username: this.registerUsernameTarget.value,
      password: this.registerPasswordTarget.value,
      email: this.registerEmailTarget.value,
      skip_standard_email: true,
      registered_via: 'GENETIC_WIZARD'
    };
  }

  handleSignin(data) {
    this.sessionTokenTarget.value = data['session_token'];
    this.isSupporterTarget.value = data['subscriber?'];
    this.acceptNewsletterTarget.value = data['accept_newsletter'];
    this.emailFieldTargets.forEach(target => target.value = data['email']);
    if (data['subscriber?']) this.hideElement(this.paymentCouponContainerTarget);
    this.nextStep();
  }

  handleRegister(data) {
    this.sessionTokenTarget.value = data.session_token;
    this.emailFieldTargets.forEach(target => target.value = data.email);
    this.nextStep();
  }

  handleSkip(email) {
    this.emailFieldTargets.forEach(target => target.value = email);
    this.skipRegistrationTarget.value = true;
    this.nextStep();
  }

  // TOS (TERMS OF SERVICE) STEP
  selectOnetime() {
    this.toggleButtonClasses(this.onetimeButtonTarget, this.monthlyButtonTarget);
    this.paymentMethodTarget.value = 'onetime';
    this.showCouponContainer();
  }

  selectMonthly() {
    this.toggleButtonClasses(this.monthlyButtonTarget, this.onetimeButtonTarget);
    this.paymentMethodTarget.value = 'monthly';
    this.hideCouponContainer();
  }

  toggleButtonClasses(selectedButton, otherButton) {
    selectedButton.classList.add('btn-primary');
    selectedButton.classList.remove('btn-secondary');
    otherButton.classList.remove('btn-primary');
    otherButton.classList.add('btn-secondary');
  }

  tosCheck() {
    const allChecked = this.tosCheckboxTargets.every(checkbox => checkbox.checked);
    this.tosSubmitTarget.disabled = !allChecked;
  }

  // COUPON FUNCTIONALITY
  applyCoupon() {
    this.discountErrorTarget.textContent = '';
    this.hideElement(this.discountErrorTarget);
    axios.post('/genetics/voucher', { code: this.couponValueTarget.value })
      .then(({ data }) => this.handleCouponResponse(data))
      .catch(() => this.setError());
  }

  // show/hide actions
  showCouponContainer() { this.showElement(this.couponContainerTarget); }
  hideCouponContainer() { this.hideElement(this.couponContainerTarget); }
  showCouponField() { this.showElement(this.couponFieldTarget); }
  hideCouponField() { this.hideElement(this.couponFieldTarget); }
  hideCouponButton() { this.hideElement(this.couponButtonTarget); }

  handleCouponResponse(data) {
    if (data.error) {
      this.displayCouponError(data.error);
    } else if (data.voucherType || data.newPrice >= 0) {
      this.applyDiscount(data);
    } else {
      this.displayCouponError();
    }
  }

  displayCouponError(error = 'Your code is invalid or there was a problem applying it. Try again later.') {
    const errorMessages = {
      expired_voucher: 'The discount code you entered has expired.',
      invalid_voucher: 'The discount code you entered was not recognized.'
    };

    this.discountErrorTarget.textContent = errorMessages[error];
    this.showElement(this.discountErrorTarget);
  }

  applyDiscount(data) {
    this.amountTarget.value = data.newPrice;
    this.hideCouponField();
    this.showElement(this.couponAppliedTarget);

    if (data.voucherType === 'free' || data.newPrice === 0) {
      this.hideElement(this.paymentCouponContainerTarget);
      this.tosSubmitTarget.value = 'Generate Free Report';
      this.tosSubmitTarget.style.background = '#7ce947';
      this.discountTarget.value = 'full';
    } else {
      this.onetimeButtonTarget.textContent = `ONE-TIME - $${data.newPrice / 100}`;
      this.discountTarget.value = true;
    }
  }

  // SUBMIT FORM
  submitWizard() {
    this.subscribeToErrors();
    this.nextStep();
    this.setPdfGenerationBar(this.avgTimeToRun);
    this.spinTimeout(600000);
  }

  // PROCESSING STEP
  setPdfGenerationBar() {
    const avgTimeToRun = this.avgTimeToRunValue || 12;
    let updates = Array(avgTimeToRun * 2).fill().map(() => Math.round(Math.random() * 100));
    let sum = updates.reduce((a, b) => a + b, 0);
    updates = updates.map((update) => Math.round((update / sum) * 100));

    window.nextUpdatePdf = 0;
    window.pdfGenerationInterval = setInterval(() => {
      window.nextUpdatePdf += 1;
      if (window.nextUpdatePdf == avgTimeToRun) {
        clearInterval(window.pdfGenerationInterval);
        this.updateProgressBar(100, 'Almost done!');
      } else {
        const percentCompleted = updates.slice(0, nextUpdatePdf).reduce((a, b) => a + b, 0);
        this.updateProgressBar(percentCompleted, 'Generating your report...');
      }
    }, 1000);
  }

  updateProgressBar(percentCompleted, stepText) {
    this.progressBarCompletedTarget.style.width = `${percentCompleted}%`;
    this.progressBarIncompleteTarget.style.width = `${100 - percentCompleted}%`;
    this.progressPercentageTarget.textContent = `${percentCompleted}%`;
    this.progressStepTextTarget.textContent = stepText;
  }

  spinTimeout(ms) {
    setTimeout(() => {
      this.processingMessageTarget.innerHTML = `<p>Your report generation request has timed out.</p><p class="color-red mt2">If you do not receive an email within ten minutes, please try again and/or email team@foundmyfitness.com with as many details as possible. Thank you!</p>`;
      this.progressBarContainerTarget.style.display = 'none';
      this.closeContainerTarget.style.display = 'block';
    }, ms);
  }

  // ERROR HANDLING
  clearError() { this.errorTarget.textContent = ''; this.hideElement(this.errorTarget); }

  setError(message = 'Something went wrong. Please try again.') {
    this.errorTarget.textContent = message;
    this.showElement(this.errorTarget);
  }

  subscribeToErrors() {
    this.subscription = App.cable.subscriptions.create(
      { channel: 'GeneticReportChannel', genetic_report: this.reportTokenTarget.value },
      {
        received: (data) => {
          if (data.error) {
            this.processingMessageTarget.style.display = 'none';
            this.progressBarContainerTarget.style.display = 'none';
            this.setError(data.error);
            this.closeContainerTarget.style.display = 'block';
          }
        }
      }
    )
  }
}
