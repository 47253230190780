import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { Component } from 'react';
import { snakeCase }        from 'lodash/string';
import { forIn }            from 'lodash/object';
import EventBus             from 'eventbusjs';
import axios                from 'axios';
import Rollbar              from 'rollbar';
import { v4 as uuidv4 }     from 'uuid';

import UploadStep             from './UploadStep';
import TemplateSelectionStep  from './TemplateSelectionStep';
import IdentificationStep     from './IdentificationStep';
import PaymentStep            from './PaymentStep';
import TermsOfService         from './TermsOfServiceStep';
import ReportProcessingStep   from './ReportProcessingStep';
import LoadingStep            from './LoadingStep';

import Wizard                 from '../Wizard';

import { OPEN_WIZARD_EVENT,
         CLOSE_WIZARD_EVENT,
         WIZARD_OPENED_EVENT } from '../../events';

const WIZARD_URL              = '/genetic_report_wizard';
const REPORT_FILE_UPLOAD_URL  = '/report_file_uploads';

// -----------------------------------------------------
// Utility Functions
// -----------------------------------------------------

if (!String.prototype.repeat) {
  String.prototype.repeat = (number) => {
    let str = '';

    while (number > 0) { number--; str += this; }

    return str;
  }
}

class GeneticReportWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      error: '',
      errorHeader: '',
      goToStep: -1,
      stepText: '',
      dots: 0,
      loading: true,
      formValues: {
        email: '',
        sessionToken: '',
        skipRegistration: false,
        stripeToken: '',
        invoicingFirstName: '',
        invoicingLastName: '',
        amount: '',
        providerProfileName: '',
        paymentMethod: 'onetime',
        genotypeTemplateId: null,
        minimumAmount: 0,
        isSupporter: false,
        rerunReportId: '',
        numberOfReruns: 0,
        coupon: '',
        preferredSupportMethod: '',
        acceptNewsletter: false
      }
    };

    this.open               = this.open.bind(this);
    this.close              = this.close.bind(this);
    this.closeFromKeyboard  = this.closeFromKeyboard.bind(this);
    this.updateFormValues   = this.updateFormValues.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    EventBus.addEventListener(OPEN_WIZARD_EVENT, this.open);
    EventBus.addEventListener(CLOSE_WIZARD_EVENT, this.close);
    window.addEventListener('keyup', this.closeFromKeyboard);
  }

  handleSubmit(event) {
    event.preventDefault();

    Rollbar.configure({
      payload: {
        person: {
          email: this.state.formValues.email
        }
      },
      ignoredMessages: [
        /\(unknown\):\s+Script\s+error/
      ]
    });

    // This report token is used to negotiate a socket connection between the
    // report generation process, on the server, and this client component.
    const reportToken = uuidv4();
    const data        = new FormData();

    data.append("[wizard]report_token", reportToken);

    forIn(this.state.formValues, (value, key) => {
      if (key != 'file' && key != 'fileKey' && typeof value !== "function") {
        data.append(`[wizard]${snakeCase(key)}`, value);
      }
    });

    this.setState({ percentCompleted: 0, stepText: 'Uploading your file' });

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({ percentCompleted, stepText: 'Uploading your file' });
      }
    };

    window.dotsInterval = setInterval(() => {
      this.setState({ dots: (this.state.dots + 1) % 4 })
    }, 1000);

    let submitData = (formData) => {
      this.subscribeToChannel(reportToken);

      axios
        .post(WIZARD_URL, formData, config)
        .then(resp => {
          this.setPdfGenerationBar();
        })
        .catch((error) => {
          try {
            this.setState({ error: error.response.data.error });
          } catch (e) { // we dont know in what format the error arrived
            this.setState({ error: 'An error occurred while processing your report. Try again later.' });
          }

          Rollbar.error("Genetic Report Submit Error", { state: this.state, error: error });
        });
    }

    if (this.state.uploadUrl) {
      axios
        .put(this.state.uploadUrl, this.state.formValues.file, {
          headers: {
            'Content-Type': this.state.formValues.file.type
          }
        }, config)
        .then((uploadData) => {
          data.append('[wizard]file_name', uploadData.config.data.name)
          data.append('[wizard]file_type', uploadData.config.data.type)
          data.append('[wizard]file_key', this.state.formValues.fileKey);
          submitData(data);
        })
        .catch((error) => {
          Rollbar.error("Genetic Report File S3 Upload Error", { state: this.state, error: error });

          data.append('[wizard]file', this.state.formValues.file);
          submitData(data);
        });
    } else {
      data.append('[wizard]file', this.state.formValues.file);
      submitData(data);
    }
  }

  setPdfGenerationBar() {
    let updates = Array(this.state.avgTimeToRun * 2).fill().map(() => Math.round(Math.random() * 100))
    let sum = updates.reduce((a, b) => a + b, 0);
    updates = updates.map((update) => Math.round((update / sum) * 100));

    window.nextUpdatePdf = 0;

    window.pdfGenerationInterval = setInterval(() => {
      window.nextUpdatePdf += 1;

      if (window.nextUpdatePdf == this.state.avgTimeToRun) {
        clearInterval(window.pdfGenerationInterval);
        this.setState({ percentCompleted: 100, stepText: 'Almost done' });
      } else {
        this.setState({
          percentCompleted: updates.slice(0, window.nextUpdatePdf).reduce((a, b) => a + b, 0),
          stepText: 'Generating your report'
        });
      }
    }, 1000);
  }

  closeFromKeyboard(event) {
    if (event.keyCode === 27) {
      this.setState({ isOpen: false });
    }
  }

  updateFormValues(fields) {
    const formValues = Object.assign({}, this.state.formValues, fields);
    this.setState({ formValues, error: '', goToStep: -1 });
  }

  open(event, state) {
    const formValues = {
      ...this.state.formValues,
      minimumAmount: Number(state.minimumAmount),
      stripeKey: state.sessionToken,
      sessionToken: state.sessionToken,
      genotypeTemplateId: state.genotypeTemplateId,
      isSupporter: state.isSupporter,
      rerunReportId: state.rerunReportId,
      numberOfReruns: state.numberOfReruns,
      coupon: state.coupon,
      preferredSupportMethod: state.preferredSupportMethod
    };

    const newState = {
      ...this.state,
      formValues,
      ...state,
      isOpen: true
    };

    this.setState(newState);

    EventBus.dispatch(WIZARD_OPENED_EVENT, {});

    axios
      .get(REPORT_FILE_UPLOAD_URL)
      .then((response) => {
        this.setState({
          formValues: {
            fileKey: response.data.key,
            ...this.state.formValues
          },
          loading: false,
          uploadUrl: response.data.uploadUrl
        });
      });
  }

  close() {
    this.setState({ isOpen: false });
  }

  subscribeToChannel(reportToken) {
    const that = this;

    App.cable.subscriptions.create(
      {
        channel:        'GeneticReportChannel',
        genetic_report: reportToken
      },
      {
        connected() {
          Rollbar.info('User connected to channel: ' + reportToken);
        },
        disconnected() {
          Rollbar.info('User disconnected from channel: ' + reportToken);
        },
        received(data) {
          clearInterval(window.dotsInterval);
          clearInterval(window.pdfGenerationInterval);
          clearTimeout(window.timeoutCounter);
          that.setState({ percentCompleted: 100, stepText: 'Done!', dots: 0 });

          if (data.error) {
            const { error, errorKind } = data;
            let goToStep  = errorKind == 'Stripe::CardError' ? 3 : -1;
            goToStep      = errorKind == 'UploadError' ? 1 : goToStep;
            that.setState({ error, goToStep });

            Rollbar.error("Genetic Wizard: handled error from backend", { state: that.state });
          } else {
            const { uploaded, redirectUrl, name } = data;
            if (uploaded) {
              window.location.replace(redirectUrl);
            } else {
              window.location.reload();
            }
          }
        }
      }
    );

    this.spinTimeout(600000);
  }

  spinTimeout(ms) {
    const that = this;

    window.timeoutCounter = setTimeout(() => {
      const errorHeader = 'Your report generation request has timed out.';
      const error = 'If you do not receive an email within ten minutes, please try again and/or email team@foundmyfitness.com with as many details as possible. Thank you!'
      that.setState({ error, errorHeader });

      Rollbar.error("Genetic Report Timeout", { state: that.state });
    }, ms);
  }

  render() {
    const { formValues } = this.state;
    const fields = Object.entries(formValues).map(([name, value]) => (
      <input
        key={name}
        type="hidden"
        name={`[wizard]${snakeCase(name)}`}
        value={value === null ? '' : value}
      />
    ));

    if (this.state.isOpen) {
      return (
        <div className="wizard">
          <h2 className="mb2">Generate Your Genetic Report</h2>

          <Wizard goToStep={this.state.goToStep} loadingComponent={LoadingStep} loading={this.state.loading}>
            {/* Step 0 */}
            <TemplateSelectionStep
              genotypeTemplateId={this.state.genotypeTemplateId}
              name={this.state.genotypeTemplateId ? "report-description-step" : "template-selection-step"}
              genotypeTemplate={this.state.genotypeTemplate}
              updateFormValues={this.updateFormValues}
              minimumAmount={this.state.formValues.minimumAmount}
            />

            {/* Step 1 */}
            <UploadStep
              updateFormValues={this.updateFormValues}
              name="wizardUploadScreen"
              numberOfReruns={this.state.numberOfReruns}
              isSignedIn={!!this.state.sessionToken}
            />

            {/* Step 2 */}
            <IdentificationStep
              authenticated={!!this.state.sessionToken}
              authenticityToken={ this.props.authenticityToken }
              name="wizardLoginScreen"
              updateFormValues={this.updateFormValues}
            />

            {/* Step 3 */}
            <PaymentStep
              name="payment-step"
              isSupporter={this.state.formValues.isSupporter}
              updateFormValues={this.updateFormValues}
              freeRerun={this.state.freeRerun}
              minimumAmount={this.state.formValues.minimumAmount}
              stripeKey={this.state.stripeKey}
              errorMessage={this.state.error}
              coupon={this.state.coupon}
              preferredSupportMethod={this.state.formValues.preferredSupportMethod}
            />

            {/* Step 4 */}
            <TermsOfService
              name="termsOfService"
              updateFormValues={this.updateFormValues}
              onSubmit={this.handleSubmit}
            />

            {/* Step 5 */}
            <ReportProcessingStep
              name="reportProcessing"
              error={this.state.error}
              errorHeader={this.state.errorHeader}
              percentCompleted={this.state.percentCompleted}
              stepText={this.state.stepText + ".".repeat(this.state.dots)}
            />
          </Wizard>
        </div>
      );
    }

    return <div />;
  }
}

export default GeneticReportWizard;
