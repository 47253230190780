/**
 * Gift Subscription Email Confirmation Controller
 * 
 * This controller manages the email confirmation modal flow for gift subscriptions.
 * When the sign-up form is submitted via the membership_form_component.html.erb is submitted, it:
 * 1. Intercepts the form submission
 * 2. onFormSubmit is called
 * 3. We show a pop-up (modal) asking the user to confirm the gift recipient's email
 * 4. If "Yes," we close the modal and submit the form programmatically.
 * 5. If "No," we close the modal and DO NOT submit the form.
 *
 * Required DOM structure:
 *  -- This is in membership_form_component.html.erb --
 * <div data-controller="gift-subscription-email-confirmation">
 *   <form data-gift-subscription-email-confirmation-target="form" data-action="submit->gift-subscription-email-confirmation#onFormSubmit">
 *     <input data-gift-subscription-email-confirmation-target="giftRecipientEmailInput">
 *   </form>
 * 
 *   -- This is in _gift_subscription_email_confirmation_modal.html.erb --
 *   <div data-gift-subscription-email-confirmation-target="modal">
 *     <span data-gift-subscription-email-confirmation-target="giftRecipientEmailDisplayInModal"></span>
 *   </div>
 * </div>
 */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    // We want to reference:
    // 1) form: the <form> itself
    // - data-gift-subscription-email-confirmation-target="form"
    //   Found in membership_form_component.html.erb on the <form> element
    //   Allows us to:
    //   1. Prevent default form submission
    //   2. Submit the form programmatically when user confirms email
    //   3. Access the form's submit button
    //
    // 2) modal: the confirmation modal
    // - data-gift-subscription-email-confirmation-target="modal"
    //   Found in _gift_subscription_email_confirmation_modal.html.erb on the root <div>
    //   Allows us to:
    //   1. Show/hide the modal
    //   2. Control the modal's visibility state
    //
    // 3) giftRecipientEmailInput: the gift recipient's email <input> in the form
    // - data-gift-subscription-email-confirmation-target="giftRecipientEmailInput"
    //   Found in membership_form_component.html.erb on the gift recipient's email <input>
    //   Allows us to:
    //   1. Get the email value that the user typed
    //   2. Re-focus the input if they click "No"
    //
    // 4) giftRecipientEmailDisplayInModal: a <span> in the modal where we show the typed email
    // - data-gift-subscription-email-confirmation-target="giftRecipientEmailDisplayInModal"
    //   Found in _gift_subscription_email_confirmation_modal.html.erb in a <span>
    //   Allows us to:
    //   1. Display the typed email in the confirmation modal
    //
    // 5) giftGiverEmailInput: the gift giver's email <input> in the form
    // - data-gift-subscription-email-confirmation-target="giftGiverEmailInput"
    //   Found in membership_form_component.html.erb on the gift giver's email <input>
    //   Allows us to:
    //   1. Get the email value that the user typed
    //
    // 6) confirmationContent: the content inside the modal
    // - data-gift-subscription-email-confirmation-target="confirmationContent"
    //   Found in _gift_subscription_email_confirmation_modal.html.erb on the <div>
    //   The content shown when confirming a valid gift recipient email
    //
    // 7) selfGiftContent: the content inside the modal
    // - data-gift-subscription-email-confirmation-target="selfGiftContent"
    //   Found in _gift_subscription_email_confirmation_modal.html.erb on the <div>
    //   The content shown when the the gift giver's email is the same as the gift recipient's email
    static targets = ['form',
        'modal',
        'giftRecipientEmailInput',
        'giftRecipientEmailDisplayInModal',
        'giftGiverEmailInput',
        'confirmationContent',
        'selfGiftContent'
    ]

    // Private getter for the submit button in the form
    // This will be called whenever we reference this._submitButton
    get _submitButton() {
        return this.formTarget.querySelector('input[type="submit"]')
    }

    // This is triggered by: data-action="submit->gift-subscription-email-confirmation#onFormSubmit"
    // on the <form>.
    onFormSubmit(event) {
        // We intercept the submission to confirm the email.
        event.preventDefault()

        // Disable the submit button
        this._submitButton.disabled = true

        // Pull the typed email from the emailInput field:
        const giftRecipientEmail = this.giftRecipientEmailInputTarget.value

        // Pull the typed email from the giftGiverEmailInput field:
        const giftGiverEmail = this.giftGiverEmailInputTarget.value

        // Check if the user is trying to gift to themselves
        if (giftRecipientEmail.toLowerCase() === giftGiverEmail.toLowerCase()) {
            this.showSelfGiftError()
        } else {
            // Show the modal with email confirmation message
            // This makes them confirm the recipient's email address before the form is submitted
            this.showEmailConfirmation(giftRecipientEmail)
        }
    }

    // Shows the modal with self-gift error message
    showSelfGiftError() {
        // Hide confirmation content, show self-gift content
        this.confirmationContentTarget.classList.add('hidden')
        this.selfGiftContentTarget.classList.remove('hidden')

        // Open the modal
        this.openModal()
    }

    // Shows the modal with email confirmation message
    showEmailConfirmation(recipientEmail) {
        // Hide self-gift content, show confirmation content
        this.selfGiftContentTarget.classList.add('hidden')
        this.confirmationContentTarget.classList.remove('hidden')

        // Display the recipient email in the modal 
        this.giftRecipientEmailDisplayInModalTarget.textContent = recipientEmail

        // Open the modal
        this.openModal()
    }

    // Called when the user clicks "Yes" in the modal
    onYes() {
        // Close the modal
        this.closeModal()
        // Now submit the form for real
        this.formTarget.submit()
    }

    // Called when the user clicks "No" in the modal
    onNo() {
        // Simply close the modal, no submission
        this.closeModal()

        // Make sure the submit button is clickable again
        this._submitButton.disabled = false

        // Re-focus the gift recipient email input
        this.giftRecipientEmailInputTarget.focus()
    }

    openModal() {
        // Remove the 'hidden' class from the modal
        this.modalTarget.classList.remove('hidden')
        // Prevent scrolling on background
        document.body.classList.add('noscroll')
        // Create an overlay to darken background
        this.createOverlay()
    }

    closeModal() {
        // Hide the modal
        this.modalTarget.classList.add('hidden')
        // Restore scrolling
        document.body.classList.remove('noscroll')
        // Remove overlay
        this.removeOverlay()
    }

    // Creates a dark overlay behind the modal
    // The modal can NOT be closed by clicking within the overlay
    createOverlay() {
        if (document.getElementsByClassName('overlay').length === 0) {
            const overlayEl = document.createElement('div')
            overlayEl.classList.add('overlay')
            document.body.appendChild(overlayEl)
        }
    }

    removeOverlay() {
        const overlays = Array.from(document.getElementsByClassName('overlay'))
        overlays.forEach(el => el.remove())
    }
}
