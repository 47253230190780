import { Controller } from "@hotwired/stimulus"

/**
 * Sets a cookie to prevent showing the omega-3 full screen welcome gate on subsequent visits.
 * The welcome gate renders from _popups.html.erb unless the cookie is present.
 */
export default class extends Controller {

    // This sets the cookie when the controller is connected
    // Since this welcome gate displays immediately, we set the cookie immediately
    connect() {
        this.setCookie()
    }

    setCookie() {
        // Delete the cookie after 7 days
        const daysToExpire = 7;

        // Build the expiration date
        const date = new Date();
        date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString() + "; ";

        // Write the cookie
        document.cookie = "omega_3_full_screen_welcome_gate_dismissed=true; " + expires + "path=/";
    }
}
