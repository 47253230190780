import { Controller } from '@hotwired/stimulus'
import axios from 'axios'

export default class extends Controller {
  delete() {
    axios.delete(`/genetics/${this.element.dataset.report}`, {
      headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }
    }).finally(() => location.reload());
  }
}
