import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    // Notes:
    // When the page loads, Stimulus looks for elements with data-controller attribute
    // The data-controller in _member_redirect_to_sign_in_modal.html.erb tells Stimulus to connect 
    // that element to the member-redirect-to-sign-in-modal controller (i.e., this file)
    // The connect method is called automatically when the controller is connected.

    // This tells Stimulus to look for an element with data-member-redirect-modal-target="modal"
    // and store it in this.modalTarget
    static targets = ["modal"]

    // As soon as Stimulus connects the controller, it automatically calls the connect method.
    // This ensures the modal opens immediately when it's added to the DOM via Turbo Stream
    connect() {
        this.openModal()
    }

    openModal() {
        // Checks if it can find the modal target (the element with data-member-redirect-modal-target="modal"),
        // which is the modal element in _member_redirect_to_sign_in_modal.html.erb
        if (this.hasModalTarget) {
            // Adds 'noscroll' to the body to prevent scrolling while the modal is open.
            document.body.classList.add('noscroll')
            // Creates an overlay to cover the entire screen.
            this.createOverlay()
        } else {
            console.log("Modal target not found")
        }
    }

    // Note: Unlike the delinquent subscription modal, we intentionally omit the close method
    // because this modal should always force a redirect to the sign-in page.
    // The redirect is handled by the redirect-timer controller which is attached to the close button
    // with data-redirect-timer-force-redirect-value="true"

    createOverlay() {
        // Check if an overlay already exists to prevent duplicates
        if (document.getElementsByClassName('overlay').length === 0) {
            const overlayEl = document.createElement('div')
            overlayEl.classList.add('overlay')
            document.body.appendChild(overlayEl)
        }
    }

    // Note: We don't need a removeOverlay method because this modal will always
    // redirect to the sign-in page, and the page navigation will naturally clean up
    // the overla
}